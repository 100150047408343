<template>
    <div class="Projectcation" style="margin-top:20px;">
        <div class="Project_button">
            <el-button type="primary" size="mini">
                <span>导入</span>
                <input id="upfile" type="file" name="upfile" class="import_input" @change="importExcel($event.target)">
            </el-button>
            <el-button :type="this.multipleSelection.length!=0?'primary':'info'" size="mini" @click="exportStu()">导出为申报格式</el-button>
        </div>
        <el-table 
            :data="tableData" 
            style="width: 100%;height:97%"
            stripe
            @selection-change="handleSelectionChange"
            :cell-style="{'text-align':'center'}"
            :header-cell-style="{background:'#f2f2f2',color:'#606266','text-align':'center'}"
        >   
            <el-table-column type="selection" label="选择" width="55"></el-table-column>
            <el-table-column prop="Itemno" label="项目编号" width="150px"></el-table-column>
            <el-table-column prop="name" label="项目名称"></el-table-column>
            <el-table-column prop="type" label="项目类型" width="120px">
                <template slot-scope="scope">
                    <span>{{scope.row.type==1?'创新训练项目':scope.row.type==2?'创业训练项目':scope.row.type==3?'创业实践项目':''}}</span>
                </template>
            </el-table-column>
            <el-table-column prop="priname" label="项目负责人" width="120px"></el-table-column>
            <el-table-column prop="people" label="项目其他成员"></el-table-column>
            <el-table-column prop="tname" label="指导教师"></el-table-column>
            <el-table-column prop="rank" label="级别" width="100px">
                <template slot-scope="scope">
                    <span>{{scope.row.rank==1?'校级':scope.row.rank==2?'省级':scope.row.rank==3?'国家级':''}}</span>
                </template>
            </el-table-column>
            <!-- <el-table-column prop="level" label="操作" width="150px">
                <template slot-scope="scope">
                    <el-button @click="qufied(scope.row)" type="text">合格</el-button>
                    <el-button @click="unqufied(scope.row)" type="text">不合格</el-button>
                </template>
            </el-table-column> -->
        </el-table>
        <el-row>
            <el-col :span="24">
                <div class="pagination">
                    <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page.sync="currentPage"
                        :page-size="pageSize"
                        layout="prev, pager, next, jumper"
                        :total="totalnum">
                    </el-pagination>
                </div>
            </el-col>
        </el-row>
    </div>
</template>
<script>
export default {
    data () {
        return {
            pageSize:10,
            totalnum:0,
            currentPage:1,
            multipleSelection:[],
            tableData:[],
        }
    },
    methods: {
        getapp(){
            this.axios.itemlist({
                params:{
                    schoolId:this.$store.state.schId
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.tableData = res.data.rows
                }else{
                    this.tableData = []
                }
            }).catch(err=>{

            })
        },
        // 合格
        qufied(){

        },
        // 不合格
        unqufied(){

        },
        // 导入
        importExcel(){

        },
        //导出
        exportStu(){

        },
         // 全选按钮
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleSizeChange(val){
            console.log(`当前页：${val}`)
        },
        handleCurrentChange(val){
            this.currentPage = val;
            this.tableData = this.newarrays[val - 1]
        },
    },
    mounted() {
        this.getapp()
    },
}
</script>
<style scoped>
@import './../../assets/css/admin/Alistofitems.css';
</style>
<style>
.Projectcation .el-table__body-wrapper{
    min-height: calc(100vh - 334px);
}
.Projectcation .el-table__empty-block{
    min-height: calc(100vh - 334px);
}
</style>